/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import config from '@config';
import HeadComponent from '@ila/Common/HeadComponent';
import WrapLayout from '@ila/components/Layout/WrapLayout';
import RenderSections from '@landing_pages/ila/Common/RenderSections';
import { performRequest } from '@utils/microsites/DatoCMSHelperService';
import { getQuery } from '@utils/microsites/ILAGqlQueries';

/* Styles */

/**
 *
 */
const ILALandingPage = ({ pageData }) => {
  // console.log('pageData', pageData);
  const seoData = [
    {
      tag: 'title',
      content: pageData?.title,
      attributes: null,
    },
    {
      tag: 'meta',
      content: null,
      attributes: {
        property: 'og:title',
        content: pageData?.title,
      },
    },
    {
      tag: 'meta',
      content: null,
      attributes: {
        name: 'description',
        content: pageData?.summary || pageData?.title,
      },
    },
  ];

  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields || seoData}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Box as="section" className="container" p="0">
        {pageData?.body?.map(section => {
          return (
            <RenderSections
              pageData={pageData}
              section={section}
              region={''}
              lang={''}
              key={nanoid(4)}
            />
          );
        })}
      </Box>
    </WrapLayout>
  );
};

export const getStaticProps = async () => {
  try {
    const response = await performRequest(getQuery(`leadership-academy`));
    // const response = await performRequest(ILA_HOME_PAGE.fetchIlaHomePage);
    const [ilaHomePagePage] = response.allIlaLandingPages;
    let pageData = ilaHomePagePage || [];
    pageData = JSON.stringify(pageData).replace(
      /https:\/\/www.datocms-assets.com\//g,
      `${config.staticPath}/d/`
    );
    pageData = JSON.parse(pageData);
    // console.log('responsess', pageData);
    return {
      props: {
        pageData: pageData ?? '',
      },
    };
  } catch (e) {
    const errorMsg = 'Failed fetching records from Dato CMS.';
    console.error(`<<ERROR_MSG: ${errorMsg}  || ERROR_CAUSE: ${e}>>`);
    throw Error(errorMsg + e);
  }
};

export default ILALandingPage;
