
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/sadhguru-academy",
      function () {
        return require("private-next-pages/sadhguru-academy/index.ila.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/sadhguru-academy"])
      });
    }
  